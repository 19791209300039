body {
  margin: 0 auto;
  padding: 0;
  font-family: "Poppins", sans-serif;
  color: #000;
  font-weight: 400;
  overflow-x: hidden;
}

main {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-weight: 600;
}

/************** header start **************/

.main-header .nav-link {
  font-size: 1.375rem;
  font-weight: 600;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(30, 38, 44, 1);
}

.navbar-light .navbar-nav .bellicon {
  background-color: #1e262c;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-light .navbar-nav .bellicon .fa-bell:before {
  color: #fff;
  font-size: 1.8rem;
}
.navbar-light .navbar-nav .bellicon .online {
  width: 10px;
  height: 10px;
  background-color: #00c600;
  display: block;
  position: absolute;
  border-radius: 100%;
  right: 18px;
  top: 18px;
  border: 1px solid #1e262c;
}
.dropdown-menu {
  border-color: #d5d5d5;
  border-radius: 10px;
}
.text-noti {
  color: #272314;
}
.view-link {
  color: #44b6af;
  text-decoration: underline;
  font-weight: 300;
}

.dropdown-menu-lg .dropdown-item {
  padding: 0.7rem 1rem;
}
.dropdown-menu:before,
.dropdown-menu:after {
  position: absolute;
  right: 20px;
  content: "";
  width: 0;
  height: 0;
}

.dropdown-menu:before {
  top: -8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #fff;
  z-index: 2;
}

.dropdown-menu:after {
  top: -9px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #d5d5d5;
  z-index: 1;
}
.dropdown-item {
  font-size: 0.875rem;
}
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: transparent;
}

.dropdown-toggle::after {
  display: none;
}
.dropdown-user .user-logedin span {
  color: #272314;
  font-size: 1.125rem;
  font-weight: 500;
}
.dropdown-user .user-logedin span small {
  font-size: 0.875rem;
  font-weight: 400;
  display: block;
}

.dropdown-user .img-size-50 {
  width: 60px;
}

/************** header css end **************/

/************** sidebar start **************/

.sidebar {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
}

[class*="sidebar-dark-"] {
  background-color: #3b3f51;
}

.main-sidebar,
.main-sidebar::before {
  width: 390px;
}

body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav)
  .content-wrapper,
body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav)
  .main-footer,
body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav)
  .main-header {
  margin-left: 390px;
}

.brand-link .brand-image {
  max-height: initial;
  width: auto;
  max-width: 100%;
  float: none;
  margin: 0;
}

.layout-fixed .brand-link {
  width: auto;
  display: block;
  padding: 1.2rem;
}

[class*="sidebar-dark-"] .sidebar a {
  color: #ffffff;
  font-weight: 300;
}

.nav-sidebar .nav-link p {
  white-space: nowrap;
}

.sidebar-mini .main-sidebar .nav-link,
.sidebar-mini-md .main-sidebar .nav-link,
.sidebar-mini-xs .main-sidebar .nav-link {
  width: calc(380px - 0.5rem * 3);
}

.nav-sidebar .bi:before,
.nav-sidebar .far:before {
  content: " ";
  vertical-align: middle;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 0 0;
  height: 20px;
  width: 20px;
  margin-right: 1rem;
  display: inline-block;
}

.nav-sidebar .bi-house:before {
  background-image: url("../images/icons/home.svg");
}
.nav-sidebar .bi-child-registration::before {
  background-image: url("../images/icons/boy.svg");
}
.nav-sidebar .bi-cwc:before {
  background-image: url("../images/icons/crosshair.svg");
}
.nav-sidebar .bi-gear:before {
  background-image: url("../images/icons/cog.svg");
}
.nav-sidebar .bi_exit:before {
  background-image: url("../images/icons/exit.svg");
}
.nav-sidebar .bi-restoration:before {
  background-image: url("../images/icons/restore.svg");
}
.nav-sidebar .bi-roles:before {
  background-image: url("../images/icons/gear.svg");
}
.nav-sidebar .bi-user:before {
  background-image: url("../images/icons/add-user.svg");
}
.nav-sidebar .bi-about:before {
  background-image: url("../images/icons/about.svg");
}

.nav-sidebar .bi-document:before {
  background-image: url("../images/icons/file.svg");
}

.nav-sidebar .nav-item.active .nav-link .bi-document:before {
  background-image: url("../images/icons/file_sel.svg");
}

.nav-sidebar .nav-item.active .nav-link .bi-house:before {
  background-image: url("../images/icons/home-fill.svg");
}
.nav-sidebar .nav-item.active .nav-link .bi-child-registration::before {
  background-image: url("../images/icons/boy-fill.svg");
}
.nav-sidebar .nav-item.active .nav-link .bi-cwc:before {
  background-image: url("../images/icons/crosshair-fill.svg");
}
.nav-sidebar .nav-item.active .nav-link .bi-gear:before {
  background-image: url("../images/icons/cog-fill.svg");
}
.nav-sidebar .nav-item.active .nav-link .bi_exit:before {
  background-image: url("../images/icons/exit-fill.svg");
}
.nav-sidebar .nav-item.active .nav-link .bi-restoration:before {
  background-image: url("../images/icons/restore-fill.svg");
}
.nav-sidebar .nav-item.active .nav-link .bi-roles:before {
  background-image: url("../images/icons/gear-fill.svg");
}
.nav-sidebar .nav-item.active .nav-link .bi-user:before {
  background-image: url("../images/icons/add-user-fill.svg");
}
.nav-sidebar .nav-item.active .nav-link .bi-about:before {
  background-image: url("../images/icons/about-fill.svg");
}

.nav-sidebar .nav-treeview .nav-link .far-product:before {
  background-image: url("../images/icons/MHP.svg");
}

.nav-sidebar .nav-treeview .nav-link .far-refer:before {
  background-image: url("../images/icons/Refer-from-CWC.svg");
}

.nav-sidebar .nav-treeview .nav-link .far-mhp:before {
  background-image: url("../images/icons/MHP.svg");
}

.nav-sidebar .nav-treeview .nav-link .far-center:before {
  background-image: url("../images/icons/buildings-white.svg");
}

.nav-sidebar .nav-treeview .nav-link .far-dropout:before {
  background-image: url("../images/icons/Dropout-icon-white.svg");
}

.nav-sidebar .nav-treeview .nav-link .far-rehabilitation:before {
  background-image: url("../images/icons/noun-walker-white.svg");
}

.nav-sidebar .nav-treeview .nav-link .far-medical:before {
  background-image: url("../images/icons/medical-kit.svg");
}

.nav-sidebar .nav-treeview .nav-link .far-educaton:before {
  background-image: url("../images/icons/graduation-hat.svg");
}

.nav-sidebar .nav-treeview .nav-link .far-meals:before {
  background-image: url("../images/icons/far-meals.svg");
}

.nav-sidebar .nav-treeview .nav-link .fa-runway:before {
  background-image: url("../images/icons/runner.svg");
}

.nav-sidebar .nav-treeview .nav-link .fa-short:before {
  background-image: url("../images/icons/Short-saty.svg");
}

.nav-sidebar .nav-treeview .nav-link .fa-transfer:before {
  background-image: url("../images/icons/exchange-white.svg");
}

.nav-sidebar .nav-treeview .nav-link .fa-other-ngo:before {
  background-image: url("../images/icons/ngo.svg");
}

.nav-sidebar .nav-treeview .nav-link .fa-io-police:before {
  background-image: url("../images/icons/police-officer.svg");
}
.nav-sidebar .nav-treeview .nav-link .fa-tthome:before {
  background-image: url("../images/icons/Transfer-Home-State.svg");
}

.nav-sidebar .nav-treeview .nav-link .fa-care:before {
  background-image: url("../images/icons/love.svg");
}

.nav-sidebar .nav-treeview .nav-link .fa-education:before {
  background-image: url("../images/icons/medal.svg");
}

.nav-sidebar .nav-treeview .nav-link .fa-runay:before {
  background-image: url("../images/icons/runner.svg");
}

.nav-sidebar .nav-treeview .nav-item.active .nav-link .far-product:before {
  background-image: url("../images/icons/MHP-fill.svg");
}

.nav-sidebar .nav-treeview .nav-item.active .nav-link .far-refer:before {
  background-image: url("../images/icons/Refer-from-CWC-fill.svg");
}

.nav-sidebar .nav-treeview .nav-item.active .nav-link.far-mhp:before {
  background-image: url("../images/icons/MHP.svg");
}

.nav-sidebar .nav-treeview .nav-item.active .nav-link .far-center:before {
  background-image: url("../images/icons/buildings.svg");
}

.nav-sidebar .nav-treeview .nav-item.active .nav-link .far-dropout:before {
  background-image: url("../images/icons/Dropout-icon.svg");
}

.nav-sidebar
  .nav-treeview
  .nav-item.active
  .nav-link
  .far-rehabilitation:before {
  background-image: url("../images/icons/noun-walker.svg");
}

.nav-sidebar .nav-treeview .nav-item.active .nav-link .far-medical:before {
  background-image: url("../images/icons/medical-kit-fill.svg");
}

.nav-sidebar .nav-treeview .nav-item.active .nav-link .far-educaton:before {
  background-image: url("../images/icons/graduation-hat-fill.svg");
}

.nav-sidebar .nav-treeview .nav-item.active .far-mhp:before {
  background-image: url("../images/icons/MHP-fill.svg");
}

.nav-sidebar .nav-treeview .nav-item.active .nav-link .fa-runway:before {
  background-image: url("../images/icons/runner-fill.svg");
}

.nav-sidebar .nav-treeview .nav-item.active .nav-link .fa-short:before {
  background-image: url("../images/icons/Short-saty-fill.svg");
}

.nav-sidebar .nav-treeview .nav-item.active .nav-link .fa-transfer:before {
  background-image: url("../images/icons/exchange-fill.svg");
}

.nav-sidebar .nav-treeview .nav-item.active .nav-link .fa-other-ngo:before {
  background-image: url("../images/icons/ngo-fill.svg");
}

.nav-sidebar .nav-treeview .nav-item.active .nav-link .far-meals:before {
  background-image: url("../images/icons/far-meals-fill.svg");
}

.nav-sidebar .nav-treeview .nav-item.active .nav-link .fa-io-police:before {
  background-image: url("../images/icons/police-officer-fill.svg");
}
.nav-sidebar .nav-treeview .nav-item.active .nav-link .fa-tthome:before {
  background-image: url("../images/icons/Transfer-Home-State-fill.svg");
}

.nav-sidebar .nav-treeview .nav-item.active .nav-link .fa-care:before {
  background-image: url("../images/icons/love-fill.svg");
}

.nav-sidebar .nav-treeview .nav-item.active .nav-link .fa-education:before {
  background-image: url("../images/icons/medal-fill.svg");
}

.nav-sidebar .nav-treeview .nav-item.active .nav-link .fa-runay:before {
  background-image: url("../images/icons/runner-fill.svg");
}

.nav-sidebar .nav-treeview .nav-item.active .nav-link {
  color: #f5c300 !important;
}

.nav-sidebar .nav-item .fa-angle-right:before {
  content: "";
  background-image: url("../images/icons/down.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 0 0;
  height: 12px;
  width: 12px;
  display: inline-block;
  font-size: 0;
}

.nav-sidebar .nav-item.active .fa-angle-right:before {
  background-image: url("../images/icons/down-fill.svg");
}

.nav-sidebar .menu-is-opening > .nav-link i.right,
.nav-sidebar .menu-is-opening > .nav-link svg.right,
.nav-sidebar .menu-open > .nav-link i.right,
.nav-sidebar .menu-open > .nav-link svg.right {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.nav-sidebar .nav-link > .right:nth-child(2),
.nav-sidebar .nav-link > p > .right:nth-child(2) {
  right: 1rem;
}

.nav-sidebar .nav-link > .right {
  top: 50%;
  transform: translateY(-50%);
}

.nav-sidebar > .nav-item {
  position: relative;
}

.nav-sidebar .menu-open > .nav-link {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link {
  padding: 1.2rem;
  background-color: rgba(255, 255, 255, 0) !important;
  border-radius: 12px;
  margin: 0;
}

.sidebar-dark-primary .nav-sidebar > .nav-item:before {
  content: "";
  position: absolute;
  left: -20px;
  top: 0;
  width: 5px;
  height: 100%;
  border-radius: 0px 10px 10px 2px;
  background: #f5c300;
  opacity: 0;
}

.sidebar-dark-primary .nav-sidebar > .nav-item.active:before,
.sidebar-light-primary .nav-sidebar > .nav-item.active:before {
  opacity: 1;
}

.sidebar-dark-primary .nav-sidebar > .nav-item.active > .nav-link,
.sidebar-light-primary .nav-sidebar > .nav-item.active > .nav-link {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #f5c300 !important;
  box-shadow: none;
}

.sidebar-mini.sidebar-collapse
  .main-sidebar:not(.sidebar-no-expand).sidebar-focused,
.sidebar-mini.sidebar-collapse .main-sidebar:not(.sidebar-no-expand):hover {
  width: 380px;
}

.sidebar-collapse.sidebar-mini
  .main-sidebar.sidebar-focused:not(.sidebar-no-expand)
  .nav-link,
.sidebar-collapse.sidebar-mini
  .main-sidebar:hover:not(.sidebar-no-expand)
  .nav-link,
.sidebar-collapse.sidebar-mini-md
  .main-sidebar.sidebar-focused:not(.sidebar-no-expand)
  .nav-link,
.sidebar-collapse.sidebar-mini-md
  .main-sidebar:hover:not(.sidebar-no-expand)
  .nav-link,
.sidebar-collapse.sidebar-mini-xs
  .main-sidebar.sidebar-focused:not(.sidebar-no-expand)
  .nav-link,
.sidebar-collapse.sidebar-mini-xs
  .main-sidebar:hover:not(.sidebar-no-expand)
  .nav-link {
  width: calc(380px - 0.5rem * 2);
}

[class*="sidebar-dark-"] .nav-sidebar > .nav-item > .nav-treeview {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  position: relative;
}

[class*="sidebar-dark-"] .nav-treeview > .nav-item > .nav-link:focus,
[class*="sidebar-dark-"] .nav-treeview > .nav-item > .nav-link:hover {
  background-color: transparent;
  color: #f5c300;
}

[class*="sidebar-dark-"] .nav-treeview > .nav-item > .nav-link {
  padding-left: 2.5rem;
}

.inner {
  padding: 2.5rem;
}

/************** sidebar end **************/

/************** form field start **************/

label {
  color: #4f5b64;
  font-weight: 400 !important;
}

label .required {
  color: #ed2124;
}

.form-control {
  font-weight: 300;
  color: #4f5b64;
  border-color: #d6dced;
  height: calc(2.25rem + 20px);
  border-radius: 10px;
}

.form-control:focus {
  border-color: #c7c7c7;
}

.form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #86847d;
}

.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #86847d;
}

.form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: #86847d;
}

.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #86847d;
}

.btn-primary {
  background-color: #00a89e;
  border-radius: 10px;
  font-size: 1.125rem;
  border-color: #00a89e;
  padding: 1rem 3rem;
  font-weight: 500;
}

.btn-primary-border {
  border: 1px solid #00a89e;
  background-color: #fff;
  color: #00a89e;
}

.btn:hover,
.btn:focus,
.btn.active {
  color: #fff;
  background-color: #f5c300;
  border-color: #f5c300;
}

.button-group {
  display: flex;
  gap: 20px;
}

.button-group .btn-primary,
.button-group .btn-danger,
.button-group .btn-secondary {
  padding: 0.8rem 1.7rem;
  display: flex;
  font-weight: 400;
  align-items: center;
  gap: 15px;
}

.button-group .btn:before {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: "";
}

.custom_select {
  position: relative;
}

.custom_select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom_select::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 18px;
  width: 18px;
  height: 15px;
  z-index: 9;
  background-image: url(../images/icons/chevron-down.svg);
  background-repeat: no-repeat;
  background-size: cover;
  pointer-events: none;
}

.input-group-text {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.page-title {
  color: #1e262c;
  font-size: 1.375rem;
}

.btn-back {
  background-color: #3b3f51;
  border-radius: 7px;
  width: 40px;
  height: 40px;
}

.btn-back img {
  transform-origin: 6px 10px;
  transform: rotate(-180deg);
  display: block;
}

.custom-checkbox .switch {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 25px;
  border-radius: 20px;
  background: #cb5a5e;
  transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  vertical-align: middle;
  cursor: pointer;
}
.custom-checkbox .switch::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 3px;
  width: 22px;
  height: 22px;
  background: #fafafa;
  border-radius: 50%;
  transition: left 0.28s;
}

.custom-checkbox .switch::after {
  content: "off";
  display: inline-block;
}

.custom-checkbox input:checked + .switch {
  background: #3598dc;
}
.custom-checkbox input:checked + .switch::before {
  left: 35px;
  background: #fff;
}

.custom-checkbox .switch::after {
  content: "OFF";
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
}

.custom-checkbox input:checked + .switch::after {
  left: 10px;
  content: "ON";
  right: inherit;
}

.option {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 0.8rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  font-weight: 300 !important;
  font-size: 0.875rem;
}

.option input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
}

.checkbox {
  position: relative;
  height: 24px;
  width: 24px;
  background-color: transparent;
  border: 1px solid #4f5b64;
  transition: all 0.15s;
  border-radius: 4px;
}

/* ---------- */
/* Checkbox 1 */

.checkbox:after {
  content: "";
  position: absolute;
  top: 11px;
  left: 3px;
  width: 0px;
  height: 0px;
  border: solid #4f5b64;
  border-width: 2px 2px 0 0;
  opacity: 0;
  transform-origin: left top;
  transform: scaleX(-1) rotate(135deg);
  transition: width 0.08s ease 0.1s, height 0.1s ease, border-color 0.3s ease,
    opacity 0.1s ease 0.2s, border-width 0.1s ease;
}

.option input:checked ~ .checkbox:after {
  opacity: 1;
  width: 8px;
  border-color: #4f5b64;
  height: 14px;
  transition: width 0.1s ease, height 0.08s ease 0.1s, border-color 0.3s ease,
    border-width 0.1s ease;
}

.checkbox-green {
  color: #05a89f;
}
.checkbox-grey {
  color: #4f5b64;
}
.checkbox-blue {
  color: #3598dc;
}
.checkbox-yellow {
  color: #f5c300;
}
.checkbox-red {
  color: #cb5a5e;
}

.checkbox-green .checkbox {
  border-color: #05a89f;
}
.checkbox-grey .checkbox {
  border-color: #4f5b64;
}
.checkbox-blue .checkbox {
  border-color: #3598dc;
}
.checkbox-yellow .checkbox {
  border-color: #f5c300;
}
.checkbox-red .checkbox {
  border-color: #cb5a5e;
}

.checkbox-green input:checked ~ .checkbox:after {
  border-color: #05a89f;
}
.checkbox-grey input:checked ~ .checkbox:after {
  border-color: #4f5b64;
}
.checkbox-blue input:checked ~ .checkbox:after {
  border-color: #3598dc;
}
.checkbox-yellow input:checked ~ .checkbox:after {
  border-color: #f5c300;
}
.checkbox-red input:checked ~ .checkbox:after {
  border-color: #cb5a5e;
}

/************** end form field **************/

/************** pageination page start **************/

.text-pages {
  font-size: 0.875rem;
  color: #272314;
}

.page-link {
  color: #3b3f51;
  border-color: #bec9d1;
  font-size: 0.875rem;
}

.page-link.active,
.page-link:hover {
  color: #fff;
  background-color: #00a89e;
  border-color: #00a89e;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  background-color: #f6f8ff;
}

.page-item:last-child .page-link {
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
  border-left: none;
}

/************** pageination page End **************/

/************** Dashboard page strat **************/

.dashboard-form .form-control {
  border-color: #d6dced;
  border-radius: 12px;
  color: #4f5b64;
  height: calc(2.25rem + 14px);
}

.dashboard-form .btn-search {
  border-radius: 12px;
  background-color: #000;
  color: #fff;
  padding: 0.7rem 1rem;
}

.dashboard-item-main h5 {
  color: #1e262c;
  font-size: 1.375rem;
}

.dashboard-item-main .small-box {
  border: 1px solid #d6dced;
  border-radius: 12px;
  height: 100%;
  margin: 0;
  width: 98%;
}

.dashboard-item-main .small-box h3 {
  color: #272314;
  font-size: 2.5rem;
}

.dashboard-item-main .small-box p {
  color: #4f5b64;
}

/************** Dashboard page end **************/

/************** login page start **************/

.login-main {
  height: 100vh;
}

.login-left {
  width: 61%;
  position: relative;
  height: 100vh;
  min-height: 100%;
}

.login-left:before {
  background-image: url(../images/login_image.jpg);
  content: "";
  display: block;
  width: 100%;
  height: calc(100vh + 150px);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-right {
  padding: 1.3rem;
  background-color: #fff;
  width: 39%;
}

.login-box,
.register-box {
  width: 100%;
  max-width: 450px;
}

.login-logo {
  max-width: 174px;
  margin: auto;
}

.login-page {
  position: relative;
  background-color: #fff !important;
}

.login-box h2 {
  color: #272314;
}

.login-box p {
  color: #86847d;
}

.login-box label {
  color: #272314;
  font-weight: 500 !important;
}

.login-box .form-control {
  border-color: #c7c7c7;
  color: #86847d;
  font-weight: 400;
}

.login-box .btn-field {
  margin-top: 4rem;
}

.login-box .in {
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-box .input-group .fa-lock:before,
.login-box .input-group .fa-envelope:before {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: "";
  display: inline-block;
}

.login-box .input-group .fa-lock:before {
  background-image: url(../images/icons/lock.svg);
  width: 16px;
  height: 20px;
}

.login-box .input-group .fa-envelope:before {
  background-image: url(../images/icons/mail.svg);
  width: 21px;
  height: 16px;
}

.Copyright-text {
  font-size: 12px;
  color: #272314;
  position: relative;
  top: 100px;
}

/************** login page start **************/

/************** filter page Start **************/

.filter-header {
  border-bottom-color: #f0f2f8 !important;
}

.filter-button .btn-primary-border:before {
  background-image: url(../images/icons/filter.svg);
  width: 25px;
  height: 17px;
}

.filter-button .btn-primary-border:hover:before,
.filter-button .btn-primary-border:active:before,
.filter-button .btn-primary-border:focus:before {
  background-image: url(../images/icons/filter-fill.svg);
}

.filter-button .btn-add:before {
  background-image: url(../images/icons/add.svg);
  width: 21px;
  height: 21px;
}

.filter-popup .modal-dialog {
  max-width: 990px;
}

.filter-popup-medium .modal-dialog {
  max-width: 520px;
}

.filter-popup .modal-content {
  border-radius: 20px;
}

.filter-popup .close {
  opacity: 1;
}

.filter-popup .button-group .btn-reset:before {
  background-image: url(../images/icons/refresh.svg);
  width: 25px;
  height: 17px;
}

.filter-popup .button-group .btn-reset:hover:before,
.filter-popup .button-group .btn-reset.active:before,
.filter-popup .button-group .btn-reset:focus:before {
  background-image: url(../images/icons/refresh-white.svg);
}

.filter-popup .button-group .btn-search:before {
  background-image: url(../images/icons/search.svg);
  width: 21px;
  height: 21px;
}

.filter-list .table-bordered td,
.filter-list .table-bordered th {
  border-color: #d6dced;
}

.filter-list .table thead {
  background-color: #3b3f51;
}

.filter-list .table thead th {
  font-weight: 500;
  color: #fff;
  text-align: center;
}

.filter-list .table td,
.filter-list .table th {
  font-size: 0.875rem;
  color: #272314;
  vertical-align: middle;
  text-align: center;
  white-space: nowrap;
}

.filter-list .table td a,
.filter-list .table td .view_btn {
  color: #3598dc;
  font-weight: 500;
}

.filter-list .table .btn {
  width: 32px;
  height: 32px;
  border-radius: 5px;
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.filter-list .table .btn:hover,
.filter-list .table .btn.active,
.filter-list .table .btn:focus {
  border: 1px solid transparent;
}

.filter-list .table .btn-lock {
  background-color: #3b3f51;
}

.filter-list .table .btn-view {
  background-color: #00a89e;
}
.filter-list .table .btn-edit {
  background-color: #3598dc;
}
.filter-list .table .btn-delete {
  background-color: #cb5a5e;
}

.filter-list .table .tag-corner {
  background-color: #f2fbfa;
  border-radius: 2rem;
  color: #00a89e;
  padding: 0.4rem 0.8rem;
  display: inline-block;
  font-weight: 400;
}

.filter-list .table .tag-product {
  background-color: #fefcf2 !important;
  color: #f6c814 !important;
}

.add-popup .modal-content {
  border-color: #707070;
  border-radius: 20px;
}
.add-popup .modal-content h4 {
  color: #0fa712;
  font-size: 1.81rem;
}
.add-popup .modal-content {
  color: #4f5b64;
}
.add-popup .modal-content .gray-round-btn {
  background-color: #eaedf7;
  border-radius: 20px;
  padding: 0.5rem 1rem;
  color: #3b3f51;
  font-weight: 500;
  display: inline-block;
}

/************** filter page end **************/

/************** registration page Start **************/

.header-page {
  border-bottom-color: #f0f2f8 !important;
}

.header-page p {
  font-size: 0.875rem;
  color: #4f5b64;
}

.registration-tab .nav {
  background-color: #f0f2f8;
  border-radius: 2rem;
}

.registration-tab li.nav-item {
  flex: 1;
}

.registration-tab .nav .nav-link {
  color: #848484;
  font-weight: 500;
  font-size: 1.062rem;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 1rem;
  border-radius: 2rem;
  line-height: normal;
  height: 100%;
  display: block;
  white-space: nowrap;
  width: 100%;
}

.registration-tab .nav-pills .nav-link:not(.active):hover {
  color: #848484;
}

.registration-tab .nav .nav-link.active {
  background-color: #f5c300;
  color: #272314;
}

.registration-form {
  max-width: 950px;
}

.registration-form .browsefile {
  position: relative;
  max-width: 400px;
  border: 1px dashed #4f5b64;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  padding: 0.7rem;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.registration-form .browsefile .fileicon {
  background-color: #ebebeb;
  color: #000;
  position: relative;
  border-radius: 10px;
  height: 80px;
  width: 80px;
}

.registration-form .browsefile .fileicon::after {
  content: "";
  position: absolute;
  background-image: url(../images/icons/file_browse_icon.svg);
  background-repeat: no-repeat;
  background-position: center center;
  height: 35px;
  top: 50%;
  width: 35px;
  background-size: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.registration-form .browsefile .textalgin {
  margin-left: 2rem;
}

.registration-form .browsefile .textalgin label {
  font-size: 14px;
  margin-bottom: 0;
  display: block;
}

.registration-form .browsefile .brtx {
  color: #333333;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 0.2rem;
  display: block;
}
.registration-form .browsefile .brtx span {
  color: #2f73f7;
}

.registration-form .browsefile p {
  color: #6f6f6f;
  font-size: 14px;
  margin: 0;
}
.registration-form .browsefile .fileChoose {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 0;
  opacity: 0;
  width: 100%;
}

.registration-form .file-custom {
  position: relative;
  width: 100%;
  height: 100%;
}
.registration-form .file-custom .label {
  font-size: 1rem;
  color: #d0d0d0;
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100% - 105px);
}
.registration-form .file-custom span {
  color: #4f5b64;
  background-color: #eaeaea;
  text-align: center;
  border-radius: 8px;
  display: inline-block;
  padding: 0.5rem 1rem;
  position: absolute;
  top: 0px;
  right: 0px;
}

.registration-form .file-custom input[type="file" i] {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 2;
}

.registration-form textarea.form-control {
  height: 120px;
}

.registration-form .button-group .btn:before {
  display: none;
}

/************** registration page End **************/

/************** children-profile page Start **************/

.children-profile {
  max-width: 21%;
  width: 100%;
}
.children-profile-main {
  max-width: 79%;
  width: 100%;
  padding-left: 2rem;
}

.children-profile .profile-edit img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  overflow: hidden;
  margin: 0 auto;
}
.children-profile .profile-edit span {
  color: #1e262c;
  font-size: 1rem;
  font-weight: 500;
}
.children-profile .profile-edit small {
  color: #4f5b64;
  font-size: 0.875rem;
}
.children-profile .profile-edit .btn-primary {
  font-size: 0.875rem;
  font-weight: 400;
  border-radius: 2rem;
  padding: 0.6rem 1.5rem;
}
.children-profile .profile-edit .btn-primary:before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  background-image: url(../images/childs-profile/pen.png);
  background-repeat: no-repeat;
  margin-right: 0.5rem;
  vertical-align: middle;
}

.children-profile .profile-edit.view-profile .btn-primary:before {
  content: "\f341";
  font-family: bootstrap-icons !important;
  background-image: none;
  font-size: 1.16rem;
  height: auto;
  width: auto;
}

.children-profile .nav .nav-link {
  font-size: 1rem;
  color: #3b3f51;
  font-weight: 400;
  display: flex;
  gap: 15px;
  align-items: center;
  border-bottom: 1px solid #f0f2f8;
  padding: 1rem;
}

.children-profile .nav .nav-link.active {
  background-color: #f0f2f8;
}

/************** children-profile page End **************/

/************** Roles Managemen page Start **************/

.add-role-popup .button-group .btn:before {
  display: none;
}

.roles-management-popup .modal-dialog {
  max-width: 700px;
}

.roles-management-popup .button-group .btn:before {
  display: none;
}

.roles-management-popup .form-label {
  padding: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

/************** Roles Managemen page End **************/

/************** no-record page Start **************/

.no-record-main {
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.no-record-main h5 {
  font-size: 1.81rem;
  color: #1e262c;
}
.no-record-main p {
  color: #4f5b64;
  font-size: 0.875rem;
}

/************** No-record page End **************/

/**************about page css start**************/

.about-content {
  width: 90%;
}

.about-title {
  gap: 15px;
  color: #1e262c;
}

.about-title:before,
.about-title:after {
  display: inline-block;
  content: "";
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  width: 22px;
  height: 30px;
}

.about-title:before {
  background-image: url(../images/about/curve-left.png);
}

.about-title:after {
  background-image: url(../images/about/curve-right.png);
}

.about-content .sub-title {
  font-size: 1.25rem;
  color: #4f5b64;
}

.about-main p {
  color: #4f5b64;
  font-size: 1rem;
  line-height: 30px;
}

.about-content .btn {
  padding: 0.9rem 1.6rem;
  border-radius: 0.7rem;
}

.vm-box {
  height: 100%;
  min-height: 192px;
}

/**************about page css end**************/

/************** custom_search_select css Start **************/

.custom_search_select .select2-container--default,
.custom_search_select .select2-selection--single {
  font-weight: 300;
  color: #4f5b64;
  border-color: #d6dced !important;
  height: calc(2.25rem + 20px) !important;
  border-radius: 10px !important;
  outline: none;
  width: 100% !important;
}

.custom_search_select .select2-selection--single {
  padding: 0.375rem 0.75rem !important;
}

.custom_search_select
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 48px;
}

.custom_search_select
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  height: 20px;
}

.custom_search_select
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  width: 18px;
  height: 15px;
  z-index: 9;
  background-image: url(../images/icons/chevron-down.svg);
  background-repeat: no-repeat;
  background-size: cover;
  pointer-events: none;
  border: 0;
  left: 0;
  margin: 0;
  top: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #d6dced;
  border-radius: 5px;
}

.select2-container--default .select2-dropdown {
  border: 1px solid #d6dced;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 0;
  top: -10px;
  padding: 0px 15px;
}

.select2-container--default .select2-results__option--selected {
  background-color: #fff;
}

.select2-container--default
  .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #fff;
  color: #4f5b64;
}

.select2-container--default .select2-dropdown .select2-search__field:focus,
.select2-container--default
  .select2-search--inline
  .select2-search__field:focus {
  border: 1px solid #d6dced;
}

/************** custom_search_select css End **************/

/************** Responsive css start**************/

@media screen and (max-width: 1439px) {
  html {
    font-size: 14px;
  }
}

@media screen and (max-width: 1199px) {
  .dashboard-item-main .icon_image {
    width: 70px;
  }

  .navbar-light .navbar-nav .bellicon {
    width: 50px;
    height: 50px;
  }

  .dropdown-user .img-size-50 {
    width: 50px;
  }
}

@media screen and (max-width: 991px) {
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav)
    .content-wrapper,
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav)
    .main-footer,
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav)
    .main-header {
    margin-left: 0;
  }

  .sidebar-collapse .main-sidebar,
  .sidebar-collapse .main-sidebar::before {
    margin-left: -390px;
  }

  .filter-popup .modal-dialog {
    max-width: 720px;
  }

  .filter-popup-medium .modal-dialog {
    max-width: 520px;
  }
}

@media screen and (max-width: 767px) {
  .navbar-light .navbar-nav .bellicon {
    width: 40px;
    height: 40px;
  }

  .dropdown-user .img-size-50 {
    width: 40px;
  }

  .navbar-light .navbar-nav .bellicon .fa-bell:before {
    font-size: 1.2rem;
  }

  .navbar-light .navbar-nav .bellicon .online {
    right: 10px;
    top: 10px;
  }

  .dashboard-item-main .icon_image {
    width: 50px;
  }

  .dashboard-form .btn-search {
    width: 100%;
  }

  .registration-tab .nav {
    border-radius: 1rem;
  }

  .registration-tab li.nav-item {
    flex: 100%;
  }

  .registration-tab .nav .nav-link {
    border-radius: 1rem;
  }

  .registration-form textarea.form-control {
    height: 80px;
  }

  .children-profile {
    max-width: 100%;
    width: 100%;
  }

  .children-profile-main {
    max-width: 100%;
    width: 100%;
    padding-left: 0rem;
  }

  .text-pages {
    padding: 1rem 0rem;
  }

  .button-group {
    display: flex;
    gap: 10px;
  }

  .button-group .btn-primary {
    gap: 10px;
  }

  .login-main {
    height: 100%;
  }

  .inner {
    padding: 1rem;
  }

  .login-logo {
    max-width: 130px;
  }

  .login-left {
    width: 100%;
    height: auto;
  }

  .login-left:before {
    padding: 200px;
    height: 100%;
  }

  .login-left {
    width: 100%;
  }
  .login-right {
    padding: 1rem;

    width: 100%;
  }

  .login-box,
  .register-box {
    width: 100%;
  }
  .login-box .btn-field {
    margin-top: 2rem;
  }

  .Copyright-text {
    top: 30px;
  }

  .about-content {
    width: 100%;
  }
}

@media screen and (max-width: 575px) {
  .dropdown-menu:before,
  .dropdown-menu:after {
    left: 20px;
  }
}

@media screen and (max-width: 419px) {
  .main-sidebar,
  .main-sidebar::before {
    width: 280px;
  }

  .option {
    gap: 0.4rem;
  }
}

/**************Responsive css End**************/
