.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  .customDatePickerWidth
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}

.react-datepicker-popper {
  z-index: 10;
}

.btn-gap-fix .button-group .btn:before {
  display: none;
}

[class*="sidebar-dark-"] .nav-treeview > .nav-item > .nav-link {
  padding-left: 1.6em;
}

.sidebar-collapse.sidebar-mini .main-sidebar .nav-sidebar .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar .nav-sidebar .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar .nav-sidebar .nav-link{
  width: 8rem
}